import {
  ImportRecord,
  InvalidImportRecord,
} from '../../../../pages/rewards/CampaignSummary/Modals/ImportFileModal';
import {
  getActiveInvitationsAPICall,
  getCampaignUsersAPICall,
  getUnsentInvitationsAPICall,
  validatePhoneNumbers,
} from '../../../../store/slices/campaignsSlice';
import { CountryCode, isPossiblePhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { setImportValidation } from '../../../../store/slices/dataSyncSlice';
import { isDateValid } from '../../../../services/utilities';

const getIsoPhone = (phone: string): string | null => {
  try {
    const parsedPhone = parsePhoneNumber(phone, 'US');
    return parsedPhone.number;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getPhoneCountryCode = (phone: string): CountryCode | null => {
  try {
    const parsedPhone = parsePhoneNumber(phone, 'US');
    return parsedPhone.country ?? null;
  } catch (_error) {
    return null;
  }
};

const validateTransactionDate = (dateString: string) => {
  const date = new Date(dateString);
  return isDateValid(date);
};

const invalidReasons = {
  invalidName: 'Invalid name',
  invalidPhone: 'Invalid phone number format',
  invalidDate: 'Invalid Date Format',
  phoneUsedInCampaign: 'Phone number already in use by campaign or has opted out',
  phoneDuplicateInFile: 'Phone number duplicated in the imported file',
  invalidTransactionDate: 'Invalid Transaction Date',
};

const tips = {
  name: 'Either a First name or Last name is required for each entry.',
  phoneRequired: 'Phone Number Required',
  invalidDateFormat: 'These formats are supported: MM-DD-YYYY, MM/DD/YYYY, Month DD, YYYY',
  invalidInternationalPhone:
    'International number: must be no more than 14 digits in E.164 format; + symbol & country code & area code & phone number; separators & spaces are ignored.',
  invalidUsPhone:
    'U.S. Phone number: must be 10 digits; area code and phone number only; separators spaces and country code are ignored.',
  emptyTransactionDate: 'Transaction Date is required',
};

export const useImportValidator = ({ campaignId }: { campaignId: string }) => {
  const { isLoading, validRecords, invalidRecords } = useTypedSelector(
    (state) => state.dataSyncSlice.importValidation,
  );
  const dispatch = useAppDispatch();

  const validateRecords = async (importRecords: ImportRecord[]) => {
    dispatch(setImportValidation({ isLoading: true, validRecords: [], invalidRecords: [] }));

    // get users and not affect any app state
    const campaignUsersDataPromise = getCampaignUsersAPICall({
      id: campaignId,
      pageable: { size: 9999 },
    });
    const unsentInvitationsDataPromise = getUnsentInvitationsAPICall({
      campaignId,
      pageable: { size: 9999 },
    });
    const activeInvitationsDataPromise = getActiveInvitationsAPICall({ campaignId });

    const apiValidRecordsPromise = validatePhoneNumbers({
      campaignId,
      phoneNumbers: [...new Set(importRecords.map((item) => item.phoneNumber))],
    });

    // api call to validate records

    const [
      campaignUsersData,
      unsentInvitationsData,
      activeInvitationsData,
      apiValidRecords,
    ] = await Promise.all([
      campaignUsersDataPromise,
      unsentInvitationsDataPromise,
      activeInvitationsDataPromise,
      apiValidRecordsPromise,
    ]);

    const apiInValidNumbers = apiValidRecords.phoneNumbers.length
      ? importRecords
          .map((item) => item.phoneNumber)
          .filter((item) => !apiValidRecords.phoneNumbers.includes(getIsoPhone(item) || ''))
          .map(getIsoPhone)
      : (importRecords.map((item) => item.phoneNumber).map(getIsoPhone) as (string | null)[]);

    const campaignPhoneNumbers = campaignUsersData.items
      .map((item) => item.phoneNumber)
      .filter(Boolean);
    const unsentInvitationsPhoneNumbers = unsentInvitationsData.items
      .map((item) => item.phoneNumber)
      .filter(Boolean);

    const inviteesPhoneNumbers = activeInvitationsData.invitations
      .map((invitation) => invitation.phoneNumber)
      .filter(Boolean);
    const pendingUsersPhoneNumbers = activeInvitationsData.pendingUsers
      .map((invitation) => invitation.phoneNumber)
      .filter(Boolean);

    //TODO: cleanup bellow vars and respective api calls

    const usedIsoPhoneNumbers = [
      ...campaignPhoneNumbers,
      ...unsentInvitationsPhoneNumbers,
      ...inviteesPhoneNumbers,
      ...pendingUsersPhoneNumbers,
    ].map(getIsoPhone);

    console.log(
      'empty',
      usedIsoPhoneNumbers.map(() => null),
    );

    const _validRecords: ImportRecord[] = [];
    const _invalidRecords: InvalidImportRecord[] = [];

    const currentImportPhones = importRecords.map((item) => getIsoPhone(item.phoneNumber));

    for (const record of importRecords) {
      if (!record.firstName && !record.lastName) {
        _invalidRecords.push({
          record,
          reason: invalidReasons.invalidName,
          tip: tips.name,
        });
        continue;
      }

      if (!record.transactionDate || !validateTransactionDate(record.transactionDate)) {
        _invalidRecords.push({
          record,
          reason: invalidReasons.invalidDate,
          tip: tips.invalidDateFormat,
        });
        continue;
      }

      if (!record.phoneNumber) {
        _invalidRecords.push({
          record,
          reason: invalidReasons.invalidPhone,
          tip: tips.phoneRequired,
        });
        continue;
      }

      const isoPhone = getIsoPhone(record.phoneNumber);

      if (!isPossiblePhoneNumber(isoPhone || '')) {
        _invalidRecords.push({
          record,
          reason: invalidReasons.invalidPhone,
          tip:
            getPhoneCountryCode(record.phoneNumber) === 'US'
              ? tips.invalidUsPhone
              : tips.invalidInternationalPhone,
        });
        continue;
      }

      // check for used phone numbers in current campaign
      if (apiInValidNumbers.includes(isoPhone)) {
        _invalidRecords.push({
          record,
          reason: invalidReasons.phoneUsedInCampaign,
        });
        continue;
      }

      // check for phone duplicates in import records
      if (currentImportPhones.filter((phone) => phone === isoPhone).length > 1) {
        _invalidRecords.push({
          record,
          reason: invalidReasons.phoneDuplicateInFile,
        });
        continue;
      }

      if (!record.transactionDate) {
        _invalidRecords.push({
          record,
          reason: invalidReasons.invalidTransactionDate,
          tip: tips.emptyTransactionDate,
        });
        continue;
      }

      _validRecords.push(record);
    }

    dispatch(
      setImportValidation({
        isLoading: false,
        validRecords: _validRecords,
        invalidRecords: _invalidRecords,
      }),
    );
  };

  return {
    validRecords,
    invalidRecords,
    isLoading,
    validateRecords,
  };
};
