import { useMemo } from 'react';
import { useTypedSelector } from '../../../../store';

export const formatDateOrDifference = (dateZFormat: string, timeToAdd: string): string => {
  // Parse the input date
  const inputDate: Date = new Date(dateZFormat);

  // Determine whether to add days or hours
  const timeUnit = timeToAdd.slice(-1); // Get the last character (either 'd' for days or 'h' for hours)
  const timeValue = parseInt(timeToAdd.slice(0, -1), 10); // Get the numeric value

  // Add the appropriate amount of time (days or hours)
  if (timeUnit === 'd') {
    inputDate.setDate(inputDate.getDate() + timeValue); // Add days
  } else if (timeUnit === 'h') {
    inputDate.setHours(inputDate.getHours() + timeValue); // Add hours
  }

  // Get the current date and time
  const currentDate: Date = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference: number = inputDate.getTime() - currentDate.getTime();

  // Calculate the difference in days and hours
  const diffInDays: number = timeDifference / (1000 * 60 * 60 * 24);
  const diffInHours: number = timeDifference / (1000 * 60 * 60);

  if (diffInDays < 0) {
    return ' 72h after you send your current invites';
  }

  // If the difference is greater than or equal to 1 day, return formatted date
  if (diffInDays >= 1) {
    const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };
    return ` on ${inputDate.toLocaleDateString('en-US', options)}`;
  } else {
    // If less than 1 day, return the difference in hours
    return Math.floor(diffInHours) === 0
      ? ` in less than an hour`
      : ` in ${Math.floor(diffInHours)} hrs`;
  }
};

export const useInvitesQueueStats = () => {
  const {
    isCampaignInvitesQueueStatsLoading: isLoading,
    campaignInvitesQueueStats: {
      unsentInvites,
      sentInTimeWindow,
      sentBatchesInTimeWindow,
      timeWindowDuration,
      timeWindowInvitationsLimit,
    },
  } = useTypedSelector((state) => state.campaigns);

  const remainingInvites = useMemo(() => {
    return timeWindowInvitationsLimit - unsentInvites - sentInTimeWindow;
  }, [timeWindowInvitationsLimit, unsentInvites, sentInTimeWindow]);

  const canCreateInvites = useMemo(() => {
    return remainingInvites > 0;
  }, [remainingInvites]);

  const dateDifference = useMemo(() => {
    return sentBatchesInTimeWindow.length
      ? formatDateOrDifference(sentBatchesInTimeWindow[0].sentAt, timeWindowDuration)
      : ' 72h after you send your current invites';
  }, [sentBatchesInTimeWindow, timeWindowDuration]);

  const canCreateAndNoUnsent = useMemo(() => {
    return canCreateInvites && !unsentInvites;
  }, [canCreateInvites, unsentInvites]);
  const canCreateAndUnsent = useMemo(() => {
    return canCreateInvites && unsentInvites;
  }, [canCreateInvites, unsentInvites]);
  const canNotCreate = !canCreateInvites;

  const canNotSend = sentInTimeWindow >= timeWindowInvitationsLimit;

  const invitesToSend =
    timeWindowInvitationsLimit - sentInTimeWindow >= unsentInvites
      ? unsentInvites
      : timeWindowInvitationsLimit - sentInTimeWindow;

  //   const lastSendDate = useMemo(() => {
  //     // return new Date('2024-07-01');
  //     if (!lastSendBatchStats || !lastSendBatchStats.sentAt) return null;
  //     return new Date(lastSendBatchStats.sentAt);
  //   }, [lastSendBatchStats]);

  return {
    isLoading,
    unsentInvites,
    sentInTimeWindow,
    sentBatchesInTimeWindow,
    timeWindowDuration,
    timeWindowInvitationsLimit,
    preparedInvites: unsentInvites,
    remainingInvites,
    canCreateInvites,
    canCreateAndNoUnsent,
    canCreateAndUnsent,
    canNotCreate,
    dateDifference,
    canNotSend,
    invitesToSend,

    // availableCustomers: unsentInvitesCount,
    // lastSendDate,
  };
};
